import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import socket from '@/store/scoket'

Vue.use(Vuex)

// 创建Vuex对象
const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    socket
  }
})

// 导出Vuex对象
export default store
