import axios from 'axios' // 引入axios
// import Store from '../store/actions'
import router from '../router/index'
import Vue from 'vue'
import { Message } from 'element-ui' // 引入element-ui Message组件zhitu.budan.site
export function request(config) {
  // 创建axios实例-hd.maer.pro---
  const instance = axios.create({ baseURL: Vue.prototype.$url, timeout: 60000 })
  // 请求拦截器
  instance.interceptors.request.use(
    config => {
      // 判断是否存在token，如果存在将每个页面header都添加token
      if (config.url === '/api/index/login') return config
      // 不需要权限即可访问
      config.headers.token = localStorage.getItem('token')
      return config
    },
    err => {
      // 对请求错误做些什么
      Message.error('请求超时')
      return Promise.reject(err)
    }
  )
  // 响应拦截器
  instance.interceptors.response.use(
    res => { return res.data },
    err => {
      const response = err.response
      // console.log(response)
      const status = response.status
      // eslint-disable-next-line eqeqeq
      if (status == undefined) { return Message.error({ message: '网络响应失败' }) }
      // eslint-disable-next-line eqeqeq
      if (status == 401) {
        localStorage.removeItem('userInfo')
        localStorage.removeItem('token')
        // setTimeout(() => { location.reload() }, 10)
        // return router.push({ path: '/login' })
      }
      // eslint-disable-next-line eqeqeq
      if (status == 500) {
        return Message.error({ message: response.statusText })
      }
      // Message.warning('网络响应异常，请稍后重试')
      return err
    }
  )
  // 发送真正的网络请求
  return instance(config)
}
