
import router from '../router'
import { LOGIN, QUERYHEADER, GETSITEINFO, GETSTOREAGE } from './mutation-types'

export default {
  login({ commit }, data) {
    commit(LOGIN, data)
  },
  // logout ({ commit }) {
  //   commit(LOGOUT)
  // },
  queryHeader({ commit }, data) {
    commit(QUERYHEADER, data)
  },
  getSiteInfo({ commit }, data) {
    commit(GETSITEINFO, data)
  },
  setRouter({ state }) {
    console.log()
    try {
      router.addRoutes(state.router)
    } catch (e) {
      console.log(e)
    }
    state.is_print = true
  },
   initStorage({commit}) {
    try {
      console.log(99)
    // console.log(res)
    commit(GETSTOREAGE)
    } catch(e) {
      console.log(e)
    }
   
  }
}
