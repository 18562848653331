<template>
  <div id="app">
    <!-- 网站标题  + 路由 -->
    <router-view v-wechat-title="$route.meta.title + ' ' + this.seo_title + ' ' + this.seo_subtitle"
      v-if="isRouterAlive" />
    <!-- <router-view v-wechat-title="$route.meta.title + ' ' + $store.getters.getSiteInfo.name" /> -->
  </div>
</template>

<script>
import { getSiteInfo } from 'network/api'
import {mapActions} from 'vuex'
// import axios from 'axios'
export default {
  name: 'async',
  metaInfo() {
    return {
      meta: this.metaData
    }
  },
  data() {
    return {
      keys: null,
      seo_title: '',
      seo_subtitle: '',
      seo_desc: '',
      seo_keyword: '',
      metaData: null,
      isRouterAlive: true // 全局刷新 https://www.jianshu.com/p/b6d7db35b6e4,
    }
  },

  created() {
    this.$store.dispatch('initStorage')
    // 获取配置信息 并存入vuex
    getSiteInfo({ key: this.keys }).then(res => {
      if (res.code === 1) {
        res.data.seo_title = res.data.name
        this.$store.dispatch('getSiteInfo', res.data)
        this.seo_desc = res.data.seo_desc
        this.seo_keyword = res.data.seo_keyword
        this.seo_title = res.data.seo_title
        this.seo_subtitle = res.data.seo_subtitle
        this.$nextTick(() => {
          this.metaData = [
            {
              name: this.seo_desc,
              content: this.seo_keyword
            }
          ]
        })
      } else {
        return this.$message.warning(res.msg)
      }
    })
      // } else {
      //   this.keys = []
      // }
      // })
      .catch(err => {
        console.log(err)
      })
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // this.$store.dispatch('setRouter')
    
    if (userInfo.storage) {
      this.$store.dispatch('setRouter')
    }
   
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  mounted() {
    setTimeout(() => {
      this.metaData = [
        {
          desc: this.seo_desc,
          keyword: this.seo_keyword
        }
      ]
    }, 1000)
  }
}
</script>

<style></style>
