import { Message } from 'element-ui'
import {UpdateOrderStatus} from 'network/api'

/**
 *
 * */

const store = {
  namespaced: true,
  state: {
    /**
     * @type {WebSocket | null}
     * */
    ws: null,
    /**
     * @type {Array<{name: string}>} printers
     * */
    printers: [],
    hasWs: false,
    order_id: ''
  },
  mutations: {
    linkSocket(state) {
      if (state.hasWs) return
      const ws = new WebSocket('ws://127.0.0.1:13888')
      ws.onclose = function(event) {
        console.log('WebSocket断开链接')
        console.log(event)
        Message.warning('打印组件断开链接')
        state.hasWs = false
      }
      ws.onerror = function(event) {
        console.log('WebSocket发生错误')
        console.log(event)
        Message.error('打印组件链接失败')
        state.hasWs = false
      }
      ws.onmessage = function(event) {
        /**
         * @type {Object} data
         * @property {String} cmd
         * */
        const data = JSON.parse(event.data)
        // 获取打印机列表
        if (data.cmd === 'getPrinters') {
          state.printers = data.printers
          console.log(state.printers)
        } else if (data.cmd === 'print') {
          Message(data.status)
        } else if (data.cmd === 'notifyPrintResult') {
          if (data.state === 'sussce') {
            UpdateOrderStatus(state.order_id)
          }
          Message(data.printStatus[0].msg)
        }
      }
      ws.onopen = function(event) {
        state.hasWs = true
        Message.success({
          message: '打印组件连接成功',
          duration: 1000
        })
      }
      state.ws = ws
    },
    /**
     * 获取打印机列表
     * */
    doGetPrinters(state) {
      const request = {
        requestID: '12345678901234567890',
        version: '1.0',
        cmd: 'getPrinters'
      }
      if (state.ws) {
        state.ws.send(JSON.stringify(request))
      }
    },
    /**
     * 获取打印面单模板
     * @param state 任意
     * @param {Object} obj
     * @param {number} obj.band_type
     * @param {string} obj.deliver_code
     * @return {void}
     * */
    async getDdexptpl(state, obj) {
      // const res = await ddexptpl(obj)
    },
    /**
     * 打一把
     * @param {{
     *  printer: string,
     *   arr: Array<{
     *   standard_template_url: string,
     *   params: string,
     *   signature: string,
     *   encryptedData: string,
     *   address: {
     *     countryCode: string,
     *     cityName: string,
     *     detailAddress: string,
     *     districtName: string,
     *     provinceName: string
     *   },
     *   contact: {
     *     mobile: string,
     *     name: string
     *   }
     *   }>
     * }} obj
     * */
    dayin(state, obj) {
      /** @type Date a */
      const a = new Date()
      console.log(obj)
      state.order_id = obj.package_ids
      const request =
        {
          cmd: 'print',
          requetID: '12345678901234567890',
          version: '1.0',
          task: {
            taskID: String(Date.parse(String(a))),
            preview: false,
            printer: obj.printer,
            documents: [{
              documentID: String(Date.parse(String(a))),
              contents: [...obj.arr]
            }]
          }
        }

      state.ws.send(JSON.stringify(request))
    },
    /**
     *  更改order_id
     *
    */
    setOrderId(state, obj) {
      state.order_id = obj.order_id
    }

  }
}

export default store
