import BaseStructure from 'components/baseStructure/BaseStructure'

export default {
  userInfo: window.localStorage.getItem('userInfo'), // 用户信息
  queryHeaderFormData: {}, // 表单数据
  siteInfo: window.localStorage.getItem('siteInfo'), // 配置信息
  router: [
    
  ],
  is_print: false,
  storage_list: []
}


